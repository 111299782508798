import {
  EVENT_DATA_LAYER_INITIALIZED,
  EVENT_JOURNEY_STARTED,
  EVENT_LANDING_PAGE_VIEWED,
  EVENT_STEP_SUBMITTED,
} from "@shared/constants";

import { logDebug } from "@shared/functions/log";

import Events from "@client/classes/data-layer/events";
import Journey from "@client/classes/models/journey";
import type { Props as JourneyProps } from "@client/classes/models/journey";
import User from "@client/classes/models/user";
import Visit from "@client/classes/models/visit";

import type Event from "@packages/types/event";
import type EventTypes from "@packages/types/events";
import type LeadForm from "@packages/types/lead-form";

class DataLayer {
  /**
   * Instance of the "Events" class
   */
  readonly events: Events;

  /**
   * Instance of the "Journey" class
   */
  readonly journey: Journey;

  /**
   * Instance of the "User" class
   */
  readonly user: User;

  /**
   * Instance of the "Visit" class
   */
  readonly visit: Visit;

  /**
   * Initialize DataLayer class
   */
  constructor() {
    this.events = new Events();
    this.journey = new Journey();
    this.user = new User();
    this.visit = new Visit();

    this.events.subscribe(
      EVENT_DATA_LAYER_INITIALIZED,
      this.handleDataLayerInitialized.bind(this),
    );

    this.events.subscribe(
      EVENT_LANDING_PAGE_VIEWED,
      this.handleLandingPageViewed.bind(this),
    );

    this.events.subscribe(
      EVENT_JOURNEY_STARTED,
      this.handleJourneyStarted.bind(this),
    );

    // Braze and Criteo need "email" and "zip code" to do their thing
    this.events.subscribe(
      EVENT_STEP_SUBMITTED,
      this.handleLeadFormStepSubmitted.bind(this),
    );

    /**
     * Note: This class gets initialized server-side and client side due to the way Next.js memoizes exports/imports.
     *       It's not helpful to log a message on the server as the DataLayer is exclusively a client-side class so
     *       only log on the client.
     */
    if (typeof window !== "undefined") {
      logDebug({ message: "Class 'DataLayer' initialized." });
    }
  }

  /**
   * Handle "dataLayerInitialized" event
   *
   * @param {string} _ Name of event being fired
   * @param {object} data Event data
   */
  private handleDataLayerInitialized(
    _: string,
    data: EventTypes.DataLayerInitialized,
  ) {
    this.user.setUuid(data.uuid);
    this.visit.setVisitId(data.visitId);
  }

  /**
   * Handle "journeyStarted" event
   *
   * @param {string} _ Name of event being fired
   * @param {object} data Event data
   */
  private handleJourneyStarted(_: string, data: JourneyProps) {
    this.journey.update(data);
  }

  /**
   * Handle "landingPageView" event
   *
   * @param {string} event Name of event being fired
   * @param {object} data Event data
   */
  private handleLandingPageViewed(event: string, data: JourneyProps) {
    // create an event that Cypress can listen for
    document.dispatchEvent(new Event(event));
  }

  /**
   * Handle "leadFormSubmitted" event
   * This is used to set the user's email and zip code
   * for Braze and Criteo
   *
   * @param {string} _ Name of event being fired
   * @param {object} data Event data
   */
  private handleLeadFormStepSubmitted(
    _: string,
    { data }: Event.Data<LeadForm.StepValues>,
  ) {
    switch (data.stepName) {
      case "debt-amount":
        this.user.setDebtAmount(data.debtAmount);
        break;
      case "email":
        this.user.setEmail(data.email);
        break;
      case "name":
        this.user.setFirstName(data.firstName);
        break;
      case "zip-code":
        this.user.setZipCode(data.zipCode);
        break;
      default:
        break;
    }
  }
}

const instance = new DataLayer();

export default instance;
