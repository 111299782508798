import Base from "@client/classes/models/base";

import type ButterCMS from "@packages/types/buttercms";

export type Props = {
  description: string;
  name: string;
  slug: string;
  published: Date | string;
  steps: ButterCMS.Wizard.Step[];
  tags: string;
  updated: Date | string;
};

export default class Journey extends Base {
  /**
   * Journey Description
   */
  private description: string = "";

  /**
   * Journey Name
   */
  private name: string = "";

  /**
   * Published Date
   */
  private published: Date | string = "";

  /**
   * Journey Slug
   */
  private slug: string = "";

  /**
   * Journey Steps
   */
  private steps: ButterCMS.Wizard.Step[] = [];

  /**
   * Journey Variation
   */
  private tags: string = "";

  /**
   * Updated Date
   */
  private updated: Date | string = "";

  /**
   * Update props after "constructor" method is called
   */
  update({ description, name, published, slug, steps, tags, updated }: Props) {
    this.description = description;
    this.name = name;
    this.published = published;
    this.slug = slug;
    this.steps = steps;
    this.tags = tags;
    this.updated = updated;
  }
}
