export default abstract class BaseModel {
  [key: string]: any;

  /**
   * Convert class props to JSON string
   *
   * @returns JSON string containing class props and values.
   */
  serialize() {
    return JSON.stringify(this);
  }

  /**
   * Get value of "private" properties
   */
  getProp<T extends object, K extends keyof T>(name: K): T[K] | string {
    const self = this as unknown as T;
    return self[name] ?? "";
  }
}
