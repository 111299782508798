import Base from "@client/classes/models/base";

export type Props = {
  uuid: string;
};

// Fields set to private so that User object is immutable.
// To store data to be used on subsequent requests please use a cookie.
export default class User extends Base {
  /**
   * User Email Address
   */
  private email: string = "";

  /**
   * User Submitted Debt Amount
   */
  private debtAmount: string = "";

  /**
   * User Submitted First Name
   */
  private firstName: string = "";

  /**
   * User UUID
   */
  private uuid: string = "";

  /**
   * User Zip Code
   */
  private zipCode: string = "";

  /**
   * Set Email after "constructor" method is called
   */
  setEmail(email: string) {
    this.email = email;
  }

  /**
   * Set Debt Amount after "constructor" method is called
   */
  setDebtAmount(debtAmount: string) {
    this.debtAmount = debtAmount;
  }

  /**
   * Set Debt Amount after "constructor" method is called
   */
  setFirstName(firstName: string) {
    this.firstName = firstName;
  }

  /**
   * Set UUID after "constructor" method is called
   */
  setUuid(uuid: string) {
    this.uuid = uuid;
  }

  /**
   * Set Zip Code after "constructor" method is called
   */
  setZipCode(zipCode: string) {
    this.zipCode = zipCode;
  }
}
