import Base from "@client/classes/models/base";

export type Props = {
  visitId: string;
};

export default class Visit extends Base {
  /**
   * Visit ID
   */
  private visitId?: string;

  /**
   * Update props after "constructor" method is called
   */
  setVisitId(visitId: string) {
    this.visitId = visitId;
  }
}
