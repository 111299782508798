import { logDebug } from "@shared/functions/log";

/**
 * Creates an extendible Publish-Subscribe (Pub-Sub) pattern.
 *
 * @see https://github.com/mroderick/PubSubJS#readme
 */
export default class PubSub {
  /**
   * A nested array containing events and their subscribers
   */
  private events: { [key: string]: Function[] } = {};

  /**
   * An array of events that were fired throughout the course of the browsing session.
   */
  private history: { event: string; data: any; source: string }[] = [];

  /**
   * Defines the "Data Layer" source saved in the history object.
   */
  private source: string = "DataLayer";

  // on logic can be leveraged to fulfill subscribe
  subscribe(event: string, handler: Function): void {
    if (!this.events[event]) {
      this.events[event] = [];
    }

    this.events[event].push(handler);
  }

  // off logic can be leveraged to fulfill unsubscribe
  unsubscribe(event: string, handler: Function): void {
    if (this.events[event]) {
      const index = this.events[event].findIndex((item) => item === handler);
      this.events[event].splice(index, 1);
    }
  }

  // emit logic can be leveraged to fulfill unsubscribe
  publish(event: string, data?: any): void {
    // publish events to `document.documentElement` in order to let Cypress tests listen for DataLayer events
    document.documentElement.dispatchEvent(new Event(event));

    this.log(event, data, this.source);

    if (this.events[event]) {
      this.events[event].forEach((handler) => handler(event, data));
    }

    // publish event to document.documentElement so Cypress or other external services can hook-in
    document.documentElement.dispatchEvent(new Event(event));
  }

  // log debugging messages to console
  log(event: string, data: Object, source: string) {
    this.history.push({ event, data, source });

    logDebug({
      message: [
        `Event '${event}' was`,
        source === this.source ? "fired" : `sent to ${source}`,
      ].join(" "),
      event: {
        name: event,
        destination: source,
        meta: data,
      },
    });
  }
}
